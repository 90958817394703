import React, { useState, useEffect } from "react";

const Cards = ({ chatIconCard, name, graph4, color, isChatCard }) => {
  const [totalChats, setTotalChats] = useState(0);
  const [totalTickets, setTotalTickets] = useState(0);

  useEffect(() => {
    const fetchTotalChats = async () => {
      try {
        // const response = await fetch("http://localhost:4001/messages/getClients/1");
        const response = await fetch("https://tfb-back.4xp.chat/messages/getClients/1");
        const data = await response.json();
        setTotalChats(data.length);
      } catch (error) {
        console.error("Error fetching total chats:", error);
      }
    };

    const fetchTotalTickets = async () => {
      try {
        // const response = await fetch("http://localhost:4001/tickets/getUsersWithTickets");
        const response = await fetch("https://tfb-back.4xp.chat/tickets/getUsersWithTickets");
        const data = await response.json();
        setTotalTickets(data.length);
      } catch (error) {
        console.error("Error fetching total tickets:", error);
      }
    };

    if (isChatCard) {
      fetchTotalChats();
    } else {
      fetchTotalTickets();
    }
  }, [isChatCard]);

  return (
    <div>
      <div className={`flex flex-col w-[350px] h-[220px] ${color} mt-5 ml-5 rounded-xl`}>
        <div className="flex flex-row items-center mt-14">
          <img src={chatIconCard} alt="chatIconCard" className="w-16 h-16"/>
          <div className="flex flex-col">
            <p className="ml-2 text-2xl font-bold">{name}</p>
            <p className="ml-2">{isChatCard ? totalChats : totalTickets}</p>
          </div>
        </div>
        <div className="flex items-center">
          <img src={graph4} alt="graph4" />
        </div>
      </div>
    </div>
  );
};

export default Cards;
