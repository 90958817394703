import React, { useEffect, useState } from "react";
import logo from "../assets/tfb.png";
import notificationIcon from "../assets/bellb.png";
import userIcon from "../assets/userb.png";
import hamburger from "../assets/hamburger.png";
import { useNotifications } from "./NotificationContext";
import { socket } from "../socket";
import { useNavigate } from 'react-router-dom';
import notificationBell from "../assets/notification.wav";

const Header = ({ toggleSidebar }) => {
  const { addNotification } = useNotifications();
  const { notifications, clearNotifications } = useNotifications();
  const [showNotifications, setShowNotifications] = useState(false);
  const [showProfile, setShowProfile] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const handleNotification = (notification) => {
      addNotification(notification);
      const audio = new Audio(notificationBell);
      audio.play();
    };

    socket.on("notification", handleNotification);

    return () => {
      socket.off("notification", handleNotification);
    };
  }, [addNotification]);

  const handleNotificationClick = () => {
    setShowNotifications(!showNotifications);
  };

  const handleClearNotifications = () => {
    clearNotifications();
    setShowNotifications(false);
  };

  const handleProfileClick = () => {
    setShowProfile(!showProfile);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('adminId');
    navigate('/login');
  };

  return (
    <>
      <div className="flex top-0 items-center justify-between p-4 bg-gray-100">
        <div className="flex items-center ml-5">
          <img src={logo} alt="logo" className="w-24 h-auto cursor-pointer" />
        </div>
        <div className="flex-grow flex justify-center">
          <input
            type="search"
            placeholder="Search..."
            className="w-[300px] ml-10 max-sm:ml-3 rounded-lg h-10 p-3 max-sm:hidden bg-gray-300"
          />
        </div>
        <div className="flex items-center pr-3">
          <div>
            <img
              src={notificationIcon}
              alt="notificationIcon"
              onClick={handleNotificationClick}
              className="w-9 ml-10 hover:cursor-pointer hover:bg-blue-700 p-2 rounded-lg"
            />
            {notifications.length > 0 && (
              <span className="absolute top-2 right-24 bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center">
                {notifications.length}
              </span>
            )}
          </div>

          <img
            src={userIcon}
            alt="userIcon"
            className="w-11 ml-10 hover:cursor-pointer hover:bg-blue-700 p-2 rounded-lg"
            onClick={handleProfileClick}
          />
          <img
            src={hamburger}
            alt="hamburger"
            onClick={toggleSidebar}
            className="w-9 ml-5 md:hidden lg:hidden min-md:hidden hover:cursor-pointer"
          />
        </div>
        {showNotifications && (
          <div className="absolute right-4 top-16 bg-white border border-gray-300 rounded-lg w-64 h-64 shadow-lg  flex flex-col justify-between">
            <ul className="overflow-y-auto">
              {notifications.map((notification, index) => (
                <li key={index} className="p-2 hover:bg-gray-200 border-b border-gray-200">
                  {notification.body}
                </li>
              ))}
            </ul>
            <button
              onClick={handleClearNotifications}
              className="w-full bg-header-gradient rounded-lg text-white py-2"
            >
              Clear Notifications
            </button>
          </div>
        )}
        {showProfile && (
          <div className="absolute right-4 top-16 bg-white border border-gray-300 rounded-lg w-28 h-22  shadow-lg flex flex-col">
            <ul className="overflow-y-auto">
              <li className="p-2 border-b border-gray-200 hover:bg-gray-100">
                <button>View Profile</button>
              </li>
            </ul>
            <ul className="overflow-y-auto">
              <li className="p-2 border-b border-gray-200 hover:bg-gray-100">
                <button onClick={handleLogout}>Logout</button>
              </li>
            </ul>
          </div>
        )}
      </div>
    </>
  );
};

export default Header;
