import React, { useEffect, useState } from "react";
import axios from "axios";
// import { useNotifications } from "../NotificationContext";
// import SearchBar from "../SearchBar";

const ChatSidebar = ({ activeChatId, selectChat, socket }) => {
  //   const { addNotification } = useNotifications();
  const [chats, setChats] = useState([]);
  const [newMessages, setNewMessages] = useState({});
  const [searchQuery, setSearchQuery] = useState("");

  // const generateAvatarUrl = (name)=>{
  //   const splitingText = name.split(" ");
  //   const firstLetter = splitingText[0].charAt(0);
  //   return firstLetter;
  // } 


  useEffect(() => {
    const adminId = localStorage.getItem("adminId");
    if (adminId) {
      axios
        .get(
          //  `http://localhost:4001/messages/getClientsWithLastMessage/${adminId}`
          `https://tfb-back.4xp.chat/messages/getClientsWithLastMessage/${adminId}`
        )
        .then((response) => {
          const loadedChats = response.data
            .map((item) => ({
              id: item.chat_id,
              name: item.name || `User ${item.chat_id}`,
              // avatar: generateAvatarUrl(item.name),
              lastMessage: item.message_text || "",
              lastMessageTime: new Date(item.last_message_time),
            }))
            console.log("Loaded ",loadedChats);
            
          setChats(loadedChats);
        })
        .catch((error) => console.error("Failed to load chat users:", error));

      // socket.emit("register", adminId);
    }

    const handleReceiveMessage = (msg) => {
      setChats((prevChats) => {
        const chatExists = prevChats.some((chat) => chat.id == msg.chatId);
        if (chatExists) {
          return prevChats
            .map((chat) => {
              if (chat.id == msg.chatId) {
                return {
                  ...chat,
                  lastMessage: msg.text,
                  lastMessageTime: new Date(msg.created_at),
                };
              }
              return chat;
            })
            .sort(
              (a, b) =>
                new Date(b.lastMessageTime) - new Date(a.lastMessageTime)
            );
        } else {
          const newChat = {
            id: msg.chatId,
            name: msg.senderName ||`User ${msg.chatId}`,
            // avatar: generateAvatarUrl(msg.chatId),
            lastMessage: msg.text,
            lastMessageTime: new Date(msg.created_at),
          };
          return [...prevChats, newChat].sort(
            (a, b) => new Date(b.lastMessageTime) - new Date(a.lastMessageTime)
          );
        }
      });
      setNewMessages((prevNewMessages) => ({
        ...prevNewMessages,
        [msg.chatId]: true,
      }));
    };

    // const handleNotification = (notification) => {
    //   console.log("Notification received:", notification);
    //   addNotification(notification);
    // };

    socket.on("receiveMessage", handleReceiveMessage);
    // socket.on("notification", handleNotification);

    return () => {
      socket.off("receiveMessage", handleReceiveMessage);
      //   socket.off("notification", handleNotification);
    };
  }, [socket]);

  const truncateLastMessage = (message) => {
    if (message.length > 10) {
      return message.slice(0, 10) + "...";
    }
    return message;
  };

  const handleSelectChat = (chat) => {
    selectChat(chat);
    setNewMessages((prevNewMessages) => ({
      ...prevNewMessages,
      [chat.id]: false,
    }));
  };

  // const handleSearchEngine = (event) => {
  //   setSearchQuery(event.target.value);
  // };

  const filteredChats = chats.filter(
    (chat) =>
      chat.name && chat.name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const totalChats = filteredChats.length;

  return (
    <div className="w-64 max-sm:w-32 bg-gray-100 border-r border-gray-300 h-full flex flex-col ">
      <header className="p-4 border-b border-gray-300 bg-header-gradient text-white rounded-lg">
        <h1 className="text-2xl font-semibold">Chats</h1>
      </header>
      <div className="flex-1 p-3 mt-4 overflow-y-auto">
        {filteredChats.length > 0 ? (
          filteredChats.map((chat, index) => (
            <div
              key={`${chat.id} - ${index}`}
              onClick={() => handleSelectChat(chat)}
              className={`flex items-center mb-4 cursor-pointer hover:bg-gray-200 p-2 rounded-md ${
                activeChatId === chat.id ? "bg-gray-300" : ""
              }`}
            >
              <div className="w-12 h-12 max-sm:hidden bg-header-gradient rounded-full mr-3 flex items-center justify-center text-xl font-semibold">
                {chat.name.charAt(0).toUpperCase()}
              </div>
              <div className="flex-1">
                <h2
                  className={`text-lg font-semibold ${
                    newMessages[chat.id] ? "text-blue-500" : ""
                  }`}
                >
                  {chat.name.charAt(0).toUpperCase() + chat.name.slice(1)}
                </h2>
                <p className="text-gray-600">
                  {truncateLastMessage(chat.lastMessage)}
                </p>
              </div>
            </div>
          ))
        ) : (
          <p className="text-gray-600 mt-4">No chats available</p>
        )}
      </div>
    </div>
  );
};

export default ChatSidebar;