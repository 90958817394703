import React, {useState} from "react";
import Banner from "../components/Banner";
import TicketSidebar from "../components/Ticket/TicketSidebar";
import TicketWindow from "../components/Ticket/TicketWindow";

const TicketManagement = () => {
  const [selectedChat, setSelectedChat] = useState(null);
  const [activeChatId, setActiveChatId] = useState(null);

  const selectChat = (chat) => {
    console.log("selectChat called with:", chat);
    setActiveChatId(chat.id);
    setSelectedChat(chat);
  };
  return (
    <div className="flex flex-col">
      <p className="text-3xl mb-3">Ticket</p>
      <Banner title="TFB Ticket Management" />
      <div className="flex w-full rounded-lg bg-white mt-5 max-h-[500px]">
        <div className="w-64 max-sm:w-32 flex-shrink-0">
          <TicketSidebar selectChat={selectChat} activeChatId={activeChatId} />
        </div>
        {selectedChat ? (
          <>
          {console.log("TicketManagement - selectedChat:", selectedChat)}
          <TicketWindow {...selectedChat} />
          </>
        ) : (
          <div className="flex-1 flex flex-col">
            <div className="flex w-[100%] bg-header-gradient h-16  rounded-lg"></div>
            <div className="flex items-center justify-center mt-16">
              <span >Select a user to see Tickets.</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TicketManagement;
