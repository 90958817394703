import React, { useState } from "react";
import Banner from "../components/Banner";
import ChatSidebar from "../components/LiveChat/ChatSidebar";
import ChatWindow from "../components/LiveChat/ChatWindow";
import { socket } from "../socket";

const LiveChat = () => {
  const [selectedChat, setSelectedChat] = useState(null);
  const [activeChatId, setActiveChatId] = useState(null);

  const selectChat = (chat) => {
    setActiveChatId(chat.id);
    setSelectedChat(chat);
  };

  return (
    <div className="flex flex-col">
      <p className="text-3xl mb-3">Live Chat</p>
      <Banner title="TFB Live Chat" />
      <div className="flex w-full rounded-lg bg-white mt-5 max-h-[500px]">
        <div className="w-64 max-sm:w-32 flex-shrink-0">
          <ChatSidebar
            activeChatId={activeChatId}
            selectChat={selectChat}
            socket={socket}
          />
        </div>
        {selectedChat ? (
          <ChatWindow selectedChat={selectedChat} socket={socket} />
        ) : (
          <div className="flex-1 flex flex-col">
            <div className="flex w-[100%] bg-header-gradient h-16  rounded-lg"></div>
            <div className="flex items-center justify-center mt-16">
              <span >Select a chat to start messaging.</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LiveChat;
