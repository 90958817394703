import React from 'react';
import { NavLink } from 'react-router-dom';

const Banner = () => {
  return (
    <div className='flex flex-row justify-center bg-banner-gradient h-[230px] rounded-3xl'>
        <div className='flex flex-col justify-center items-center'>
            <p className='text-6xl max-sm:text-4xl text-white ml-9 font-semibold tracking-wider'>Welcome to CSM</p>
            <a href="https://trilliumfinancialbroker.com/" target="_blank" rel="noopener noreferrer">
            <p className='text-xl text-white ml-3'>BY TRILLIUM FINANCIAL BROKER</p></a>
            <NavLink to="/home/LiveChat">
                <button className='p-2 m-4 bg-gray-300 rounded-lg hover:bg-blue-700 hover:text-white'>Live Chat</button>
            </NavLink>
        </div>
    </div>
  )
}

export default Banner;