import React, { useState, useEffect, useRef } from "react";
import attachment from "../../assets/attachment.png";
import axios from "axios";

const ChatWindow = ({ selectedChat, socket }) => {
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [file, setFile] = useState(null);
  const containerRef = useRef(null);
  const typingTimeoutRef = useRef(null);
  const fileInputRef = useRef(null);

  const generateUniqueFileName = (originalName) => {
    const timestamp = new Date().getTime();
    const name = "myFile";
    const extension = originalName.split('.').pop();
    return `${name}_${timestamp}.${extension}`;
  };

  useEffect(() => {
    if (selectedChat) {
      console.log("Selected chat:", selectedChat.id);
      axios
        // .get(`http://localhost:4001/messages/getAllMessages/${selectedChat.id}`)
        .get(`https://tfb-back.4xp.chat/messages/getAllMessages/${selectedChat.id}`)
        .then((response) => {
          setMessages(
            response.data.map((msg) => ({
              id: msg.message_id,
              sender: msg.sender_type === "admin" ? "admin" : "user",
              text: msg.message_text,
              timestamp: msg.created_at,
              attachment: msg.attachment ? msg.attachment : null,
              generatedAttachment: msg.generatedAttachment ? msg.generatedAttachment : null,
            }))
          );
        })
        .catch((err) => console.error("Error loading messages:", err));
    }
    axios
      // .get(`http://localhost:4001/users/getClientById/${selectedChat.id}`)
      .get(`https://tfb-back.4xp.chat/users/getClientById/${selectedChat.id}`)
      .then((response) => {
        console.log(response.data);
        setUserInfo(response.data);
      })
      .catch((err) => console.error("Error loading user Info:", err));
  }, [selectedChat]);

  useEffect(() => {
    const handleReceiveMessage = (msg) => {
      if (msg.chatId == selectedChat.id) {
        console.log("Received message:", msg); // Add this log
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            id: msg.message_id,
            sender: msg.sender_type === "admin" ? "admin" : "user",
            text: msg.text,
            timestamp: new Date(msg.created_at),
            attachment: msg.attachment ? msg.attachment : null,
            generatedAttachment: msg.generatedAttachment ? msg.generatedAttachment : null,
          },
        ]);

      }
    };

    socket.on("receiveMessage", handleReceiveMessage);

    return () => {
      socket.off("receiveMessage", handleReceiveMessage);
    };
  }, [selectedChat, socket]);

  const handleInputChange = (e) => {
    setMessage(e.target.value);
    socket.emit("typing", { chatId: selectedChat.id, isTyping: true });
    clearTimeout(typingTimeoutRef.current);
    typingTimeoutRef.current = setTimeout(() => {
      socket.emit("typing", { chatId: selectedChat.id, isTyping: false });
    }, 1000);
  };

  const handleSendMessage = (e) => {
    e.preventDefault();
    if (message.trim() || file) {
      const uniqueFileName = file ? generateUniqueFileName(file.name) : null;
      const msg = {
        text: message.trim(),
        senderId: localStorage.getItem("adminId"),
        receiverId: selectedChat.id,
        senderType: "admin",
        timestamp: new Date(),
        chatId: selectedChat.id,
        attachment: uniqueFileName,
      };
      console.log("Sending message:", msg);
      socket.emit("sendMessage", msg);
      socket.emit("typing", { chatId: selectedChat.id, isTyping: false });

      setMessage("");
      setFile(null);

      if (file) {
        const formData = new FormData();
        formData.append("myFile", file, uniqueFileName);
        formData.append("chatId", selectedChat.id);
        formData.append("senderId", localStorage.getItem("adminId"));
        formData.append("senderType", "admin");
        formData.append("receiverId", selectedChat.id);
        formData.append("timestamp", new Date().toISOString());
        formData.append("attachment", uniqueFileName);
        
        try {
          // axios.post("http://localhost:4001/upload/sendFile", formData)
          axios.post("https://tfb-back.4xp.chat/upload/sendFile", formData)
            .then(response => {
              console.log("File upload response:", response.data);
              setMessages((prevMessages) => [
                ...prevMessages,
                {
                  id: response.data.messageId,
                  sender: "admin",
                  text: message,
                  timestamp: new Date(),
                  attachment: uniqueFileName,
                  // generatedAttachment: response.data.generatedAttachment,
                },
              ]);
            })
            .catch(err => {
              console.error("Error uploading file:", err);
            });
        } catch (err) {
          console.error("Error in file upload:", err);
        }
      } else {
        setMessages((prevMessages) => [
          ...prevMessages,  
          {
            id: new Date().getTime(),
            sender: "admin",
            text: message,
            timestamp: new Date(),
            attachment: null,
            generatedAttachment: null,
          },
        ]);
      }
    }
  };

  const scrollToBottom = () => {
    const scrollContainer = containerRef.current;
    scrollContainer.scrollTop = scrollContainer.scrollHeight;
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const formatMessageDate = (timestamp) => {
    const messageDate = new Date(timestamp);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    if (messageDate.toDateString() === today.toDateString()) {
      return "TODAY";
    } else if (messageDate.toDateString() === yesterday.toDateString()) {
      return "YESTERDAY";
    } else {
      return messageDate
        .toLocaleDateString("en-US", {
          month: "short",
          day: "numeric",
          year: "numeric",
        })
        .toUpperCase();
    }
  };

  const groupMessagesByDate = (messages) => {
    const groups = {};
    messages.forEach((message) => {
      const date = formatMessageDate(message.timestamp);
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(message);
    });
    return Object.entries(groups);
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  const handleAttachmentClick = () => {
    fileInputRef.current.click();
  };


  return (
    <div className="flex-1 flex flex-col">
      {/* Chat title section */}
      <div className="p-4 bg-header-gradient text-white rounded-lg">
        <h1
          className="text-2xl font-semibold cursor-pointer inline-block"
          onClick={toggleModal}
        >
          {selectedChat.name.charAt(0).toUpperCase() +
            selectedChat.name.slice(1)}
        </h1>
      </div>
      {isModalOpen && (
        <>
          <div
            className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
          ></div>
          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <h3
                        className=" text-2xl font-semibold leading-6 text-gray-900"
                        id="modal-title"
                      >
                        User Profile
                      </h3>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Name: {userInfo.name}
                        </p>
                        <p className="text-sm text-gray-500">
                          Email: {userInfo.email}
                        </p>
                        <p className="text-sm text-gray-500">
                          Contact: {userInfo.contact}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={toggleModal}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {/* Messages display section */}
      <div
        ref={containerRef}
        className="flex-1 p-4 space-y-2 bg-gray-200 overflow-y-auto"
      >
        {groupMessagesByDate(messages).map(([date, dateMessages]) => (
          <div key={date}>
            <div className="text-center flex  justify-center text-sm  text-gray-500 my-2  px-2 py-1 rounded-full">
              {date}
            </div>
            {dateMessages.map((message) => (
              <div
                key={message.id + message.timestamp}
                className={`flex ${
                  message.sender === "admin" ? "justify-end" : "justify-start"
                } mt-2`}
              >
                <div
                  className={`max-w-md max-sm:mr-10 px-2 py-2 rounded-lg shadow ${
                    message.sender === "admin"
                      ? "bg-[#2532d6] text-white"
                      : "bg-white text-gray-700"
                  }`}
                >
                  <p>{message.text}</p>
                  {message.attachment && (
                    <div className="mt-2">
                      <a
                        // href={`http://localhost:4001/uploads/${message.attachment}`}
                        href={`https://tfb-back.4xp.chat/uploads/${message.attachment}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-sm text-blue-300 hover:text-blue-100 underline"
                      >
                        {message.attachment}
                      </a>
                    </div>
                  )}
                  <p className="text-xs text-right mt-2">
                    {new Date(message.timestamp).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </p>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>

      {/* Message input section */}
      <form
        onSubmit={handleSendMessage}
        className="p-2 sm:p-4 border-t border-gray-300 w-full"
      >
        <div className="flex flex-col sm:flex-row w-full">
          <div className="flex w-full mb-2 sm:mb-0 items-center">
            <input
              type="text"
              value={file ? `${message}[Attachment: ${file.name}]` : message}
              onChange={handleInputChange}
              className="flex-1 min-w-0 focus:outline-none text-sm sm:text-base p-2 rounded-l-lg border"
              placeholder="Type a message..."
            />
            <button
              type="button"
              onClick={handleAttachmentClick}
              className="px-3 py-2 focus:outline-none bg-white border border-l-0 rounded-r-lg flex-shrink-0"
            >
              <img src={attachment} alt="Attach file" className="w-5 h-5 sm:w-6 sm:h-6" />
            </button>
            <input
              type="file"
              name="myFile"
              ref={fileInputRef}
              onChange={handleFileChange}
              className="hidden"
            />
          </div>
          <button
            type="submit"
            className="bg-[#2532d6] text-white px-4 py-2 rounded-lg sm:rounded-l-none w-full sm:w-auto text-sm sm:text-base mt-2 sm:mt-0 sm:ml-2"
          >
            Send
          </button>
        </div>
      </form>
    </div>
  );
};

export default ChatWindow;
