import React from "react";
import Banner from "../components/Banner";
import SearchBar from "../components/knowledgebase/SearchBar";
import Faqs from "../components/knowledgebase/Faqs";

const KnowledgeBase = () => {
  return (
    <div className="flex flex-col">
      <div>
        <p className="text-3xl mb-3">Knowledge Base</p>
        <Banner title="TFB Knowledge Base" />
      </div>
      <div>
        <SearchBar />
      </div>
      <div>
        <Faqs />
      </div>
    </div>
  );
};

export default KnowledgeBase;
