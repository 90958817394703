import React, { useState, useEffect } from "react";
import axios from "axios";

const TicketWindow = (selectedChat) => {
  const [tickets, setTickets] = useState([]);
  
  useEffect(() => {
    if (selectedChat.id) {
      axios
        // .get(`http://localhost:4001/tickets/getTickets/${ selectedChat.id}`)
        .get(`https://tfb-back.4xp.chat/tickets/getTickets/${ selectedChat.id}`)
        .then((response) => {
          setTickets(
            response.data.map((ticket) => ({
              id: ticket.ticket_id,
              description: ticket.ticket_message,
              created_at: ticket.created_at,
            }))
          );
        })
        .catch((err) => console.error("Error loading tickets:", err));
    }
  }, [selectedChat.id]);

  return (
    <div className="flex-1 flex flex-col">
      <div className="p-4 bg-header-gradient text-white rounded-lg">
        <h1 className="text-2xl  font-semibold">{selectedChat.name.charAt(0).toUpperCase() + selectedChat.name.slice(1).split("@")[0]}</h1>
      </div>
      <div className="flex-1 p-4 space-y-2 bg-gray-200 overflow-y-auto">
        {tickets.map((ticket) => (
          <div key={ticket.id} className="p-4 bg-white rounded-lg shadow-md">
            <h3>Ticket Id # {ticket.id}</h3>
            <p>{ticket.description}</p>
            <p>{new Date(ticket.created_at).toLocaleString()}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
export default TicketWindow;
